exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-chatbot-development-services-js": () => import("./../../../src/pages/ai-chatbot-development-services.js" /* webpackChunkName: "component---src-pages-ai-chatbot-development-services-js" */),
  "component---src-pages-ai-chatbot-scheduling-assistant-solution-js": () => import("./../../../src/pages/ai-chatbot-scheduling-assistant-solution.js" /* webpackChunkName: "component---src-pages-ai-chatbot-scheduling-assistant-solution-js" */),
  "component---src-pages-ai-consulting-services-js": () => import("./../../../src/pages/ai-consulting-services.js" /* webpackChunkName: "component---src-pages-ai-consulting-services-js" */),
  "component---src-pages-ai-development-services-js": () => import("./../../../src/pages/ai-development-services.js" /* webpackChunkName: "component---src-pages-ai-development-services-js" */),
  "component---src-pages-ai-integration-services-js": () => import("./../../../src/pages/ai-integration-services.js" /* webpackChunkName: "component---src-pages-ai-integration-services-js" */),
  "component---src-pages-ai-recruitment-solutions-js": () => import("./../../../src/pages/ai-recruitment-solutions.js" /* webpackChunkName: "component---src-pages-ai-recruitment-solutions-js" */),
  "component---src-pages-aiops-mlops-services-js": () => import("./../../../src/pages/aiops-mlops-services.js" /* webpackChunkName: "component---src-pages-aiops-mlops-services-js" */),
  "component---src-pages-apache-flink-js": () => import("./../../../src/pages/apache-flink.js" /* webpackChunkName: "component---src-pages-apache-flink-js" */),
  "component---src-pages-apache-kafka-js": () => import("./../../../src/pages/apache-kafka.js" /* webpackChunkName: "component---src-pages-apache-kafka-js" */),
  "component---src-pages-aws-ci-cd-pipeline-services-js": () => import("./../../../src/pages/aws-ci-cd-pipeline-services.js" /* webpackChunkName: "component---src-pages-aws-ci-cd-pipeline-services-js" */),
  "component---src-pages-aws-cloud-architecture-services-js": () => import("./../../../src/pages/aws-cloud-architecture-services.js" /* webpackChunkName: "component---src-pages-aws-cloud-architecture-services-js" */),
  "component---src-pages-aws-cloud-computing-process-js": () => import("./../../../src/pages/aws-cloud-computing-process.js" /* webpackChunkName: "component---src-pages-aws-cloud-computing-process-js" */),
  "component---src-pages-aws-cloud-computing-services-js": () => import("./../../../src/pages/aws-cloud-computing-services.js" /* webpackChunkName: "component---src-pages-aws-cloud-computing-services-js" */),
  "component---src-pages-aws-cloud-consulting-services-js": () => import("./../../../src/pages/aws-cloud-consulting-services.js" /* webpackChunkName: "component---src-pages-aws-cloud-consulting-services-js" */),
  "component---src-pages-aws-cloud-migration-services-js": () => import("./../../../src/pages/aws-cloud-migration-services.js" /* webpackChunkName: "component---src-pages-aws-cloud-migration-services-js" */),
  "component---src-pages-aws-cloud-security-services-js": () => import("./../../../src/pages/aws-cloud-security-services.js" /* webpackChunkName: "component---src-pages-aws-cloud-security-services-js" */),
  "component---src-pages-aws-managed-services-js": () => import("./../../../src/pages/aws-managed-services.js" /* webpackChunkName: "component---src-pages-aws-managed-services-js" */),
  "component---src-pages-aws-microservices-architecture-consulting-services-js": () => import("./../../../src/pages/aws-microservices-architecture-consulting-services.js" /* webpackChunkName: "component---src-pages-aws-microservices-architecture-consulting-services-js" */),
  "component---src-pages-aws-serverless-services-js": () => import("./../../../src/pages/aws-serverless-services.js" /* webpackChunkName: "component---src-pages-aws-serverless-services-js" */),
  "component---src-pages-blockchain-app-development-services-js": () => import("./../../../src/pages/blockchain-app-development-services.js" /* webpackChunkName: "component---src-pages-blockchain-app-development-services-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cloud-assessment-services-js": () => import("./../../../src/pages/cloud-assessment-services.js" /* webpackChunkName: "component---src-pages-cloud-assessment-services-js" */),
  "component---src-pages-cloud-audit-optimisation-and-performance-services-js": () => import("./../../../src/pages/cloud-audit-optimisation-and-performance-services.js" /* webpackChunkName: "component---src-pages-cloud-audit-optimisation-and-performance-services-js" */),
  "component---src-pages-cloud-consulting-services-js": () => import("./../../../src/pages/cloud-consulting-services.js" /* webpackChunkName: "component---src-pages-cloud-consulting-services-js" */),
  "component---src-pages-cloud-managed-services-js": () => import("./../../../src/pages/cloud-managed-services.js" /* webpackChunkName: "component---src-pages-cloud-managed-services-js" */),
  "component---src-pages-cloud-migration-services-js": () => import("./../../../src/pages/cloud-migration-services.js" /* webpackChunkName: "component---src-pages-cloud-migration-services-js" */),
  "component---src-pages-cloud-native-development-services-js": () => import("./../../../src/pages/cloud-native-development-services.js" /* webpackChunkName: "component---src-pages-cloud-native-development-services-js" */),
  "component---src-pages-cloud-optimisation-js": () => import("./../../../src/pages/cloud-optimisation.js" /* webpackChunkName: "component---src-pages-cloud-optimisation-js" */),
  "component---src-pages-cloud-services-and-solutions-js": () => import("./../../../src/pages/cloud-services-and-solutions.js" /* webpackChunkName: "component---src-pages-cloud-services-and-solutions-js" */),
  "component---src-pages-computer-vision-services-and-solutions-js": () => import("./../../../src/pages/computer-vision-services-and-solutions.js" /* webpackChunkName: "component---src-pages-computer-vision-services-and-solutions-js" */),
  "component---src-pages-cross-platform-application-frameworks-js": () => import("./../../../src/pages/cross-platform-application-frameworks.js" /* webpackChunkName: "component---src-pages-cross-platform-application-frameworks-js" */),
  "component---src-pages-datadog-monitoring-services-js": () => import("./../../../src/pages/datadog-monitoring-services.js" /* webpackChunkName: "component---src-pages-datadog-monitoring-services-js" */),
  "component---src-pages-devops-automation-services-js": () => import("./../../../src/pages/devops-automation-services.js" /* webpackChunkName: "component---src-pages-devops-automation-services-js" */),
  "component---src-pages-devops-ci-cd-services-js": () => import("./../../../src/pages/devops-ci-cd-services.js" /* webpackChunkName: "component---src-pages-devops-ci-cd-services-js" */),
  "component---src-pages-devops-consulting-services-js": () => import("./../../../src/pages/devops-consulting-services.js" /* webpackChunkName: "component---src-pages-devops-consulting-services-js" */),
  "component---src-pages-devops-microservices-js": () => import("./../../../src/pages/devops-microservices.js" /* webpackChunkName: "component---src-pages-devops-microservices-js" */),
  "component---src-pages-devops-serverless-architecture-js": () => import("./../../../src/pages/devops-serverless-architecture.js" /* webpackChunkName: "component---src-pages-devops-serverless-architecture-js" */),
  "component---src-pages-devops-services-js": () => import("./../../../src/pages/devops-services.js" /* webpackChunkName: "component---src-pages-devops-services-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../../src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-django-development-js": () => import("./../../../src/pages/django-development.js" /* webpackChunkName: "component---src-pages-django-development-js" */),
  "component---src-pages-docker-and-kubernetes-js": () => import("./../../../src/pages/docker-and-kubernetes.js" /* webpackChunkName: "component---src-pages-docker-and-kubernetes-js" */),
  "component---src-pages-elasticsearchsolution-js": () => import("./../../../src/pages/elasticsearchsolution.js" /* webpackChunkName: "component---src-pages-elasticsearchsolution-js" */),
  "component---src-pages-engagement-model-js": () => import("./../../../src/pages/engagement-model.js" /* webpackChunkName: "component---src-pages-engagement-model-js" */),
  "component---src-pages-extend-dedicated-development-team-js": () => import("./../../../src/pages/extend-dedicated-development-team.js" /* webpackChunkName: "component---src-pages-extend-dedicated-development-team-js" */),
  "component---src-pages-finance-app-development-js": () => import("./../../../src/pages/finance-app-development.js" /* webpackChunkName: "component---src-pages-finance-app-development-js" */),
  "component---src-pages-financial-software-development-js": () => import("./../../../src/pages/financial-software-development.js" /* webpackChunkName: "component---src-pages-financial-software-development-js" */),
  "component---src-pages-frontend-development-js": () => import("./../../../src/pages/frontend-development.js" /* webpackChunkName: "component---src-pages-frontend-development-js" */),
  "component---src-pages-fuel-delivery-software-js": () => import("./../../../src/pages/fuel-delivery-software.js" /* webpackChunkName: "component---src-pages-fuel-delivery-software-js" */),
  "component---src-pages-full-stack-web-development-js": () => import("./../../../src/pages/full-stack-web-development.js" /* webpackChunkName: "component---src-pages-full-stack-web-development-js" */),
  "component---src-pages-gas-delivery-software-js": () => import("./../../../src/pages/gas-delivery-software.js" /* webpackChunkName: "component---src-pages-gas-delivery-software-js" */),
  "component---src-pages-generative-ai-development-services-js": () => import("./../../../src/pages/generative-ai-development-services.js" /* webpackChunkName: "component---src-pages-generative-ai-development-services-js" */),
  "component---src-pages-guide-to-hiring-a-team-js": () => import("./../../../src/pages/guide-to-hiring-a-team.js" /* webpackChunkName: "component---src-pages-guide-to-hiring-a-team-js" */),
  "component---src-pages-healthcare-app-development-js": () => import("./../../../src/pages/healthcare-app-development.js" /* webpackChunkName: "component---src-pages-healthcare-app-development-js" */),
  "component---src-pages-healthcare-software-development-js": () => import("./../../../src/pages/healthcare-software-development.js" /* webpackChunkName: "component---src-pages-healthcare-software-development-js" */),
  "component---src-pages-hire-dedicated-developers-js": () => import("./../../../src/pages/hire-dedicated-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-developers-js" */),
  "component---src-pages-hire-django-developer-js": () => import("./../../../src/pages/hire-django-developer.js" /* webpackChunkName: "component---src-pages-hire-django-developer-js" */),
  "component---src-pages-hire-laravel-developer-js": () => import("./../../../src/pages/hire-laravel-developer.js" /* webpackChunkName: "component---src-pages-hire-laravel-developer-js" */),
  "component---src-pages-hire-mean-stack-developer-js": () => import("./../../../src/pages/hire-mean-stack-developer.js" /* webpackChunkName: "component---src-pages-hire-mean-stack-developer-js" */),
  "component---src-pages-hire-mern-stack-developer-js": () => import("./../../../src/pages/hire-mern-stack-developer.js" /* webpackChunkName: "component---src-pages-hire-mern-stack-developer-js" */),
  "component---src-pages-hire-mobile-app-developers-js": () => import("./../../../src/pages/hire-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-hire-mobile-app-developers-js" */),
  "component---src-pages-hire-mongodb-developers-js": () => import("./../../../src/pages/hire-mongodb-developers.js" /* webpackChunkName: "component---src-pages-hire-mongodb-developers-js" */),
  "component---src-pages-hire-software-developers-js": () => import("./../../../src/pages/hire-software-developers.js" /* webpackChunkName: "component---src-pages-hire-software-developers-js" */),
  "component---src-pages-hire-symfony-framework-developers-js": () => import("./../../../src/pages/hire-symfony-framework-developers.js" /* webpackChunkName: "component---src-pages-hire-symfony-framework-developers-js" */),
  "component---src-pages-hire-web-developers-js": () => import("./../../../src/pages/hire-web-developers.js" /* webpackChunkName: "component---src-pages-hire-web-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-of-things-iot-applications-development-company-js": () => import("./../../../src/pages/internet-of-things-iot-applications-development-company.js" /* webpackChunkName: "component---src-pages-internet-of-things-iot-applications-development-company-js" */),
  "component---src-pages-iphone-application-development-js": () => import("./../../../src/pages/iphone-application-development.js" /* webpackChunkName: "component---src-pages-iphone-application-development-js" */),
  "component---src-pages-laravel-development-js": () => import("./../../../src/pages/laravel-development.js" /* webpackChunkName: "component---src-pages-laravel-development-js" */),
  "component---src-pages-last-mile-delivery-logistics-management-software-js": () => import("./../../../src/pages/last-mile-delivery-logistics-management-software.js" /* webpackChunkName: "component---src-pages-last-mile-delivery-logistics-management-software-js" */),
  "component---src-pages-life-at-peerbits-js": () => import("./../../../src/pages/life-at-peerbits.js" /* webpackChunkName: "component---src-pages-life-at-peerbits-js" */),
  "component---src-pages-llm-development-services-js": () => import("./../../../src/pages/llm-development-services.js" /* webpackChunkName: "component---src-pages-llm-development-services-js" */),
  "component---src-pages-logistics-app-development-js": () => import("./../../../src/pages/logistics-app-development.js" /* webpackChunkName: "component---src-pages-logistics-app-development-js" */),
  "component---src-pages-logistics-software-development-js": () => import("./../../../src/pages/logistics-software-development.js" /* webpackChunkName: "component---src-pages-logistics-software-development-js" */),
  "component---src-pages-lp-ai-development-services-js": () => import("./../../../src/pages/lp/ai-development-services.js" /* webpackChunkName: "component---src-pages-lp-ai-development-services-js" */),
  "component---src-pages-lp-aws-cloud-computing-services-js": () => import("./../../../src/pages/lp/aws-cloud-computing-services.js" /* webpackChunkName: "component---src-pages-lp-aws-cloud-computing-services-js" */),
  "component---src-pages-machine-learning-development-services-js": () => import("./../../../src/pages/machine-learning-development-services.js" /* webpackChunkName: "component---src-pages-machine-learning-development-services-js" */),
  "component---src-pages-managed-it-services-js": () => import("./../../../src/pages/managed-it-services.js" /* webpackChunkName: "component---src-pages-managed-it-services-js" */),
  "component---src-pages-mean-stack-development-js": () => import("./../../../src/pages/mean-stack-development.js" /* webpackChunkName: "component---src-pages-mean-stack-development-js" */),
  "component---src-pages-mern-stack-development-js": () => import("./../../../src/pages/mern-stack-development.js" /* webpackChunkName: "component---src-pages-mern-stack-development-js" */),
  "component---src-pages-mobile-app-quality-assurance-testing-services-js": () => import("./../../../src/pages/mobile-app-quality-assurance-testing-services.js" /* webpackChunkName: "component---src-pages-mobile-app-quality-assurance-testing-services-js" */),
  "component---src-pages-mobile-app-strategy-js": () => import("./../../../src/pages/mobile-app-strategy.js" /* webpackChunkName: "component---src-pages-mobile-app-strategy-js" */),
  "component---src-pages-mobile-application-development-js": () => import("./../../../src/pages/mobile-application-development.js" /* webpackChunkName: "component---src-pages-mobile-application-development-js" */),
  "component---src-pages-monitoring-and-server-maintenance-services-js": () => import("./../../../src/pages/monitoring-and-server-maintenance-services.js" /* webpackChunkName: "component---src-pages-monitoring-and-server-maintenance-services-js" */),
  "component---src-pages-mvp-development-company-js": () => import("./../../../src/pages/mvp-development-company.js" /* webpackChunkName: "component---src-pages-mvp-development-company-js" */),
  "component---src-pages-oil-and-gas-industry-app-solution-js": () => import("./../../../src/pages/oil-and-gas-industry-app-solution.js" /* webpackChunkName: "component---src-pages-oil-and-gas-industry-app-solution-js" */),
  "component---src-pages-on-demand-app-development-js": () => import("./../../../src/pages/on-demand-app-development.js" /* webpackChunkName: "component---src-pages-on-demand-app-development-js" */),
  "component---src-pages-on-demand-delivery-app-development-js": () => import("./../../../src/pages/on-demand-delivery-app-development.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-app-development-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-patient-engagement-software-development-js": () => import("./../../../src/pages/patient-engagement-software-development.js" /* webpackChunkName: "component---src-pages-patient-engagement-software-development-js" */),
  "component---src-pages-portugal-strategic-partnership-js": () => import("./../../../src/pages/portugal-strategic-partnership.js" /* webpackChunkName: "component---src-pages-portugal-strategic-partnership-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-product-engineering-services-js": () => import("./../../../src/pages/product-engineering-services.js" /* webpackChunkName: "component---src-pages-product-engineering-services-js" */),
  "component---src-pages-prompt-chaining-ai-services-js": () => import("./../../../src/pages/prompt-chaining-ai-services.js" /* webpackChunkName: "component---src-pages-prompt-chaining-ai-services-js" */),
  "component---src-pages-rag-as-a-service-js": () => import("./../../../src/pages/rag-as-a-service.js" /* webpackChunkName: "component---src-pages-rag-as-a-service-js" */),
  "component---src-pages-redis-js": () => import("./../../../src/pages/redis.js" /* webpackChunkName: "component---src-pages-redis-js" */),
  "component---src-pages-remote-patient-monitoring-solutions-js": () => import("./../../../src/pages/remote-patient-monitoring-solutions.js" /* webpackChunkName: "component---src-pages-remote-patient-monitoring-solutions-js" */),
  "component---src-pages-request-quote-js": () => import("./../../../src/pages/request-quote.js" /* webpackChunkName: "component---src-pages-request-quote-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-retail-mobility-solutions-js": () => import("./../../../src/pages/retail-mobility-solutions.js" /* webpackChunkName: "component---src-pages-retail-mobility-solutions-js" */),
  "component---src-pages-saas-development-company-js": () => import("./../../../src/pages/saas-development-company.js" /* webpackChunkName: "component---src-pages-saas-development-company-js" */),
  "component---src-pages-schedule-a-meeting-js": () => import("./../../../src/pages/schedule-a-meeting.js" /* webpackChunkName: "component---src-pages-schedule-a-meeting-js" */),
  "component---src-pages-schedule-meeting-js": () => import("./../../../src/pages/schedule-meeting.js" /* webpackChunkName: "component---src-pages-schedule-meeting-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-smart-energy-solution-js": () => import("./../../../src/pages/smart-energy-solution.js" /* webpackChunkName: "component---src-pages-smart-energy-solution-js" */),
  "component---src-pages-smart-home-automation-systems-js": () => import("./../../../src/pages/smart-home-automation-systems.js" /* webpackChunkName: "component---src-pages-smart-home-automation-systems-js" */),
  "component---src-pages-software-development-company-portugal-js": () => import("./../../../src/pages/software-development-company-portugal.js" /* webpackChunkName: "component---src-pages-software-development-company-portugal-js" */),
  "component---src-pages-software-development-services-js": () => import("./../../../src/pages/software-development-services.js" /* webpackChunkName: "component---src-pages-software-development-services-js" */),
  "component---src-pages-symfony-development-js": () => import("./../../../src/pages/symfony-development.js" /* webpackChunkName: "component---src-pages-symfony-development-js" */),
  "component---src-pages-technology-stack-js": () => import("./../../../src/pages/technology-stack.js" /* webpackChunkName: "component---src-pages-technology-stack-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-top-app-development-company-js": () => import("./../../../src/pages/top-app-development-company.js" /* webpackChunkName: "component---src-pages-top-app-development-company-js" */),
  "component---src-pages-top-mobile-app-development-company-usa-js": () => import("./../../../src/pages/top-mobile-app-development-company-usa.js" /* webpackChunkName: "component---src-pages-top-mobile-app-development-company-usa-js" */),
  "component---src-pages-transportation-app-development-js": () => import("./../../../src/pages/transportation-app-development.js" /* webpackChunkName: "component---src-pages-transportation-app-development-js" */),
  "component---src-pages-ui-ux-design-js": () => import("./../../../src/pages/ui-ux-design.js" /* webpackChunkName: "component---src-pages-ui-ux-design-js" */),
  "component---src-pages-warehouse-management-software-js": () => import("./../../../src/pages/warehouse-management-software.js" /* webpackChunkName: "component---src-pages-warehouse-management-software-js" */),
  "component---src-pages-wearable-app-development-js": () => import("./../../../src/pages/wearable-app-development.js" /* webpackChunkName: "component---src-pages-wearable-app-development-js" */),
  "component---src-pages-web-application-development-js": () => import("./../../../src/pages/web-application-development.js" /* webpackChunkName: "component---src-pages-web-application-development-js" */),
  "component---src-pages-wellness-and-fitness-app-development-js": () => import("./../../../src/pages/wellness-and-fitness-app-development.js" /* webpackChunkName: "component---src-pages-wellness-and-fitness-app-development-js" */),
  "component---src-pages-whitepaper-a-complete-guide-to-iot-logistics-js": () => import("./../../../src/pages/whitepaper-a-complete-guide-to-iot-logistics.js" /* webpackChunkName: "component---src-pages-whitepaper-a-complete-guide-to-iot-logistics-js" */),
  "component---src-pages-whitepaper-a-complete-guide-to-on-demand-mobile-app-development-js": () => import("./../../../src/pages/whitepaper-a-complete-guide-to-on-demand-mobile-app-development.js" /* webpackChunkName: "component---src-pages-whitepaper-a-complete-guide-to-on-demand-mobile-app-development-js" */),
  "component---src-pages-whitepaper-automotive-js": () => import("./../../../src/pages/whitepaper-automotive.js" /* webpackChunkName: "component---src-pages-whitepaper-automotive-js" */),
  "component---src-pages-whitepaper-smart-homes-js": () => import("./../../../src/pages/whitepaper-smart-homes.js" /* webpackChunkName: "component---src-pages-whitepaper-smart-homes-js" */),
  "component---src-pages-whitepaper-smart-retail-js": () => import("./../../../src/pages/whitepaper-smart-retail.js" /* webpackChunkName: "component---src-pages-whitepaper-smart-retail-js" */),
  "component---src-pages-why-offshore-js": () => import("./../../../src/pages/why-offshore.js" /* webpackChunkName: "component---src-pages-why-offshore-js" */),
  "component---src-pages-why-outsource-js": () => import("./../../../src/pages/why-outsource.js" /* webpackChunkName: "component---src-pages-why-outsource-js" */),
  "component---src-pages-why-outsource-to-india-js": () => import("./../../../src/pages/why-outsource-to-india.js" /* webpackChunkName: "component---src-pages-why-outsource-to-india-js" */),
  "component---src-pages-why-outsource-to-peerbits-js": () => import("./../../../src/pages/why-outsource-to-peerbits.js" /* webpackChunkName: "component---src-pages-why-outsource-to-peerbits-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-main-categories-js": () => import("./../../../src/templates/blog-main-categories.js" /* webpackChunkName: "component---src-templates-blog-main-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-agile-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/agile-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-agile-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ai-applications-in-finance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/ai-applications-in-finance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ai-applications-in-finance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ai-in-customer-service-boosting-user-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/ai-in-customer-service-boosting-user-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ai-in-customer-service-boosting-user-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ai-in-healthcare-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/ai-in-healthcare-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ai-in-healthcare-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-angular-vs-react-vs-vue-which-is-the-best-choice-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/angular-vs-react-vs-vue-which-is-the-best-choice/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-angular-vs-react-vs-vue-which-is-the-best-choice-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-apache-kafka-use-cases-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/apache-kafka-use-cases/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-apache-kafka-use-cases-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-api-testing-methodologies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/api-testing-methodologies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-api-testing-methodologies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-asynchronous-programming-in-python-with-coroutines-asyncio-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/asynchronous-programming-in-python-with-coroutines-asyncio/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-asynchronous-programming-in-python-with-coroutines-asyncio-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automate-deployments-monitoring-with-devops-microservices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/automate-deployments-monitoring-with-devops-microservices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automate-deployments-monitoring-with-devops-microservices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-aws-cloud-cost-optimization-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/aws-cloud-cost-optimization/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-aws-cloud-cost-optimization-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-aws-consulting-planning-to-execution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/aws-consulting-planning-to-execution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-aws-consulting-planning-to-execution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-aws-migration-best-practices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/aws-migration-best-practices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-aws-migration-best-practices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-devops-automation-aws-cdk-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/benefits-of-devops-automation-aws-cdk/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-devops-automation-aws-cdk-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-biggest-iot-security-challenges-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/biggest-iot-security-challenges/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-biggest-iot-security-challenges-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-infrastructure-with-cdk-for-iac-in-java-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/build-infrastructure-with-cdk-for-iac-in-java/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-infrastructure-with-cdk-for-iac-in-java-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-chatbot-published-on-messenger-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/chatbot-published-on-messenger/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-chatbot-published-on-messenger-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-aws-managed-services-partner-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/choose-aws-managed-services-partner/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-aws-managed-services-partner-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-nodejs-for-web-application-development-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/choose-nodejs-for-web-application-development-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-nodejs-for-web-application-development-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-machine-learning-algorithm-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/choose-right-machine-learning-algorithm-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-machine-learning-algorithm-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-technology-stack-for-web-application-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/choose-right-technology-stack-for-web-application-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-technology-stack-for-web-application-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-devops-for-scalable-b-2-b-product-engineering-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/cloud-based-devops-for-scalable-b2b-product-engineering/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-devops-for-scalable-b-2-b-product-engineering-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-common-digital-transformation-challenges-and-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/common-digital-transformation-challenges-and-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-common-digital-transformation-challenges-and-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-cloud-consulting-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-for-cloud-consulting-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-cloud-consulting-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-java-spring-boot-microservices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-for-java-spring-boot-microservices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-java-spring-boot-microservices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-aiops-automate-with-ai-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-to-aiops-automate-with-ai/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-aiops-automate-with-ai-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-choose-a-software-development-partner-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-to-choose-a-software-development-partner/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-choose-a-software-development-partner-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-cloud-native-developmment-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-to-cloud-native-developmment/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-cloud-native-developmment-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-release-management-in-devops-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-to-release-management-in-devops/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-release-management-in-devops-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-testing-angular-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/complete-guide-to-testing-angular-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-testing-angular-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-concepts-and-applications-of-elasticsearch-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/concepts-and-applications-of-elasticsearch/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-concepts-and-applications-of-elasticsearch-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-create-chat-application-server-using-node-js-socket-io-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/create-chat-application-server-using-node-js-socket-io/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-create-chat-application-server-using-node-js-socket-io-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-create-secure-rest-api-in-node-js-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/create-secure-rest-api-in-node-js/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-create-secure-rest-api-in-node-js-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-development-practices-for-nodejs-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/development-practices-for-nodejs-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-development-practices-for-nodejs-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-devops-for-enterprise-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/devops-for-enterprise/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-devops-for-enterprise-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-devsecops-complete-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/devsecops-complete-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-devsecops-complete-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-transformation-in-healthcare-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/digital-transformation-in-healthcare/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-transformation-in-healthcare-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-transformation-strategy-with-low-code-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/digital-transformation-strategy-with-low-code/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-transformation-strategy-with-low-code-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-enhance-your-sales-with-ooh-advertising-management-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/enhance-your-sales-with-ooh-advertising-management-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-enhance-your-sales-with-ooh-advertising-management-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-enterprise-app-features-boost-employee-engagement-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/enterprise-app-features-boost-employee-engagement/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-enterprise-app-features-boost-employee-engagement-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-essentials-of-product-engineering-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/essentials-of-product-engineering-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-essentials-of-product-engineering-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-know-about-lean-startup-methodology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/everything-you-need-know-about-lean-startup-methodology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-know-about-lean-startup-methodology-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-about-apache-kafka-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/everything-you-need-to-about-apache-kafka/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-everything-you-need-to-about-apache-kafka-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-factors-to-consider-before-outsource-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/factors-to-consider-before-outsource-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-factors-to-consider-before-outsource-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-foundation-model-vs-llm-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/foundation-model-vs-llm-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-foundation-model-vs-llm-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-gps-vehicle-tracking-can-transform-fleet-management-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/gps-vehicle-tracking-can-transform-fleet-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-gps-vehicle-tracking-can-transform-fleet-management-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-for-python-web-scraping-libraries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-for-python-web-scraping-libraries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-for-python-web-scraping-libraries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-build-generative-ai-applications-with-aws-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-build-generative-ai-applications-with-aws/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-build-generative-ai-applications-with-aws-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-choose-right-outsourcing-models-for-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-choose-right-outsourcing-models-for-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-choose-right-outsourcing-models-for-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-custom-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-custom-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-custom-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-offshore-dot-net-developement-team-india-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-hire-offshore-dot-net-developement-team-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-offshore-dot-net-developement-team-india-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-implementing-iot-testing-framework-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-implementing-iot-testing-framework/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-implementing-iot-testing-framework-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-python-frameworks-for-scalable-microservices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-python-frameworks-for-scalable-microservices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-python-frameworks-for-scalable-microservices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-software-development-lifecycle-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/guide-to-software-development-lifecycle/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-software-development-lifecycle-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-healthcare-software-development-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/healthcare-software-development-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-healthcare-software-development-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-healthcare-software-development-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/healthcare-software-development-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-healthcare-software-development-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hiring-dedicated-virtual-android-app-developers-can-help-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/hiring-dedicated-virtual-android-app-developers-can-help-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hiring-dedicated-virtual-android-app-developers-can-help-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hiring-offshore-reactjs-developers-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/hiring-offshore-reactjs-developers-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hiring-offshore-reactjs-developers-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-ai-is-impacting-everday-life-for-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-ai-is-impacting-everday-life-for-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-ai-is-impacting-everday-life-for-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-can-beacons-help-asset-tracking-and-management-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-can-beacons-help-asset-tracking-and-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-can-beacons-help-asset-tracking-and-management-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-healthcare-mobile-solution-improve-patient-care-in-hospital-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-healthcare-mobile-solution-improve-patient-care-in-hospital/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-healthcare-mobile-solution-improve-patient-care-in-hospital-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-iot-driving-urban-development-and-smart-cities-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-iot-driving-urban-development-and-smart-cities/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-iot-driving-urban-development-and-smart-cities-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-iot-is-building-the-smart-offices-of-tomorrow-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-iot-is-building-the-smart-offices-of-tomorrow/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-iot-is-building-the-smart-offices-of-tomorrow-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-serverless-architecture-boost-time-to-market-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-serverless-architecture-boost-time-to-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-serverless-architecture-boost-time-to-market-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-choose-software-development-methodology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-to-choose-software-development-methodology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-choose-software-development-methodology-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-uber-for-trucking-helps-logistics-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/how-uber-for-trucking-helps-logistics-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-uber-for-trucking-helps-logistics-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-iot-on-fintech-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/impact-of-iot-on-fintech-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-iot-on-fintech-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-iot-on-transport-and-logistics-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/impact-of-iot-on-transport-and-logistics-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-iot-on-transport-and-logistics-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-code-quality-in-custom-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/importance-of-code-quality-in-custom-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-code-quality-in-custom-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-user-centered-design-in-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/importance-of-user-centered-design-in-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-user-centered-design-in-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-node-js-performance-at-scale-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/improve-node-js-performance-at-scale/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-node-js-performance-at-scale-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-customer-experience-with-chatbots-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/improve-your-customer-experience-with-chatbots/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-customer-experience-with-chatbots-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-industries-benefits-of-software-outsourcing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/industries-benefits-of-software-outsourcing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-industries-benefits-of-software-outsourcing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-internet-of-things-healthcare-applications-benefits-and-challenges-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/internet-of-things-healthcare-applications-benefits-and-challenges/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-internet-of-things-healthcare-applications-benefits-and-challenges-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-internet-of-things-industrial-automation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/internet-of-things-industrial-automation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-internet-of-things-industrial-automation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-iot-applications-in-different-industries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/iot-applications-in-different-industries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-iot-applications-in-different-industries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-healthcare-telemedicine-and-remote-patient-monitoring-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/iot-in-healthcare-telemedicine-and-remote-patient-monitoring/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-healthcare-telemedicine-and-remote-patient-monitoring-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/iot-in-manufacturing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-predictive-maintenance-and-quality-control-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/iot-in-manufacturing-predictive-maintenance-and-quality-control/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-predictive-maintenance-and-quality-control-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-use-cases-and-benefits-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/iot-in-manufacturing-use-cases-and-benefits/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-iot-in-manufacturing-use-cases-and-benefits-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-it-staff-augmentation-vs-managed-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/it-staff-augmentation-vs-managed-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-it-staff-augmentation-vs-managed-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-low-code-no-code-in-devops-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/low-code-no-code-in-devops/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-low-code-no-code-in-devops-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-machine-learning-in-finance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/machine-learning-in-finance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-machine-learning-in-finance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-machine-learning-vs-traditional-programming-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/machine-learning-vs-traditional-programming/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-machine-learning-vs-traditional-programming-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-managed-cloud-services-complete-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/managed-cloud-services-complete-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-managed-cloud-services-complete-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-microservices-architecture-for-saas-based-product-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/microservices-architecture-for-saas-based-product-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-microservices-architecture-for-saas-based-product-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-app-development-by-peerbits-secure-rank-goodfirms-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/mobile-app-development-by-peerbits-secure-rank-goodfirms/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-app-development-by-peerbits-secure-rank-goodfirms-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nfc-payment-system-for-mobile-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/nfc-payment-system-for-mobile-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nfc-payment-system-for-mobile-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-django-vs-laravel-back-end-web-framework-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/nodejs-vs-django-vs-laravel-back-end-web-framework/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-django-vs-laravel-back-end-web-framework-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-golang-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/nodejs-vs-golang/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-golang-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-java-backend-development-comparison-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/nodejs-vs-java-backend-development-comparison/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-java-backend-development-comparison-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-python-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/nodejs-vs-python/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-python-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-developer-rates-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/offshore-developer-rates/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-developer-rates-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-software-development-center-in-india-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/offshore-software-development-center-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-software-development-center-in-india-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-patient-based-medical-data-management-case-study-gulf-coast-registry-gcr-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/patient-based-medical-data-management-case-study-gulf-coast-registry-gcr/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-patient-based-medical-data-management-case-study-gulf-coast-registry-gcr-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-celebrating-11-years-success-2022-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/peerbits-celebrating-11-years-success-2022/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-celebrating-11-years-success-2022-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-guide-to-managing-offshore-remote-team-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/peerbits-guide-to-managing-offshore-remote-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-guide-to-managing-offshore-remote-team-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-hailed-by-clutch-top-app-development-partner-2021-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/peerbits-hailed-by-clutch-top-app-development-partner-2021/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-hailed-by-clutch-top-app-development-partner-2021-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-solutions-awarded-amongst-top-indian-app-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/peerbits-solutions-awarded-amongst-top-indian-app-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-peerbits-solutions-awarded-amongst-top-indian-app-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-potential-security-threats-for-mobile-financial-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/potential-security-threats-for-mobile-financial-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-potential-security-threats-for-mobile-financial-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-process-of-hiring-dedicated-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/process-of-hiring-dedicated-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-process-of-hiring-dedicated-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-proctoring-solutions-for-secure-assessments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/proctoring-solutions-for-secure-assessments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-proctoring-solutions-for-secure-assessments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-pros-and-cons-of-cloud-computing-in-healthcare-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/pros-and-cons-of-cloud-computing-in-healthcare/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-pros-and-cons-of-cloud-computing-in-healthcare-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-quality-assurance-process-peerbits-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/quality-assurance-process-peerbits/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-quality-assurance-process-peerbits-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-react-vs-angular-which-one-popular-javascript-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/react-vs-angular-which-one-popular-javascript-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-react-vs-angular-which-one-popular-javascript-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-choose-reactjs-for-your-web-development-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/reasons-to-choose-reactjs-for-your-web-development-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-choose-reactjs-for-your-web-development-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-redis-db-usage-guide-why-what-how-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/redis-db-usage-guide-why-what-how/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-redis-db-usage-guide-why-what-how-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-software-development-cost-with-dedicated-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/reduce-software-development-cost-with-dedicated-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-software-development-cost-with-dedicated-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionizing-cold-chain-management-for-healthcare-and-logistics-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/revolutionizing-cold-chain-management-for-healthcare-and-logistics/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionizing-cold-chain-management-for-healthcare-and-logistics-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-and-iot-in-asset-tracking-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/rfid-and-iot-in-asset-tracking/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-and-iot-in-asset-tracking-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-right-time-to-hire-offshore-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/right-time-to-hire-offshore-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-right-time-to-hire-offshore-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-node-js-for-enterprise-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/rise-of-node-js-for-enterprise-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-node-js-for-enterprise-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-risks-of-outsourcing-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/risks-of-outsourcing-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-risks-of-outsourcing-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-devops-consulting-in-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/role-of-devops-consulting-in-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-devops-consulting-in-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-devops-in-digital-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/role-of-devops-in-digital-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-devops-in-digital-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-remote-patient-monitoring-to-improve-patient-outcomes-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/role-of-remote-patient-monitoring-to-improve-patient-outcomes/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-remote-patient-monitoring-to-improve-patient-outcomes-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-application-architecture-design-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/saas-application-architecture-design-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-application-architecture-design-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-safeguard-restful-api-in-saas-product-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/safeguard-restful-api-in-saas-product-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-safeguard-restful-api-in-saas-product-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-school-bus-tracking-solution-with-iot-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/school-bus-tracking-solution-with-iot/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-school-bus-tracking-solution-with-iot-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scrum-project-management-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/scrum-project-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scrum-project-management-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-significance-of-having-right-team-with-right-structure-for-your-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/significance-of-having-right-team-with-right-structure-for-your-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-significance-of-having-right-team-with-right-structure-for-your-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-software-development-outsourcing-is-good-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/software-development-outsourcing-is-good-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-software-development-outsourcing-is-good-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-software-development-performance-metrics-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/software-development-performance-metrics/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-software-development-performance-metrics-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-software-release-planning-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/software-release-planning/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-software-release-planning-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-staff-augmentation-successful-model-to-extend-your-team-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/staff-augmentation-successful-model-to-extend-your-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-staff-augmentation-successful-model-to-extend-your-team-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-most-comprehensive-guide-beacon-installation-maintenance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/the-most-comprehensive-guide-beacon-installation-maintenance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-most-comprehensive-guide-beacon-installation-maintenance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-boost-performance-of-angular-web-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/tips-to-boost-performance-of-angular-web-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-boost-performance-of-angular-web-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-handle-time-zone-differences-with-offshore-teams-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/tips-to-handle-time-zone-differences-with-offshore-teams/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-handle-time-zone-differences-with-offshore-teams-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-benefits-of-migrating-to-aws-cloud-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/top-benefits-of-migrating-to-aws-cloud-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-benefits-of-migrating-to-aws-cloud-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-devops-implementation-challenges-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/top-devops-implementation-challenges/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-devops-implementation-challenges-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-devops-principles-woks-and-examples-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/ultimate-guide-to-devops-principles-woks-and-examples/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-devops-principles-woks-and-examples-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-hiring-react-js-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/ultimate-guide-to-hiring-react-js-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-hiring-react-js-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-vuejs-is-growing-international-development-community-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/vuejs-is-growing-international-development-community/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-vuejs-is-growing-international-development-community-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-warehouse-smart-inventory-management-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/warehouse-smart-inventory-management-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-warehouse-smart-inventory-management-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-web-application-architecture-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/web-application-architecture/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-web-application-architecture-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-web-application-development-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/web-application-development-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-web-application-development-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-nodejs-fast-and-its-best-use-cases-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/why-nodejs-fast-and-its-best-use-cases/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-nodejs-fast-and-its-best-use-cases-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-product-owner-must-hire-software-development-company-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/why-product-owner-must-hire-software-development-company/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-product-owner-must-hire-software-development-company-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-reactjs-benefits-business-owners-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=D:/React JS/Products/peerbits-react-website-new/content/blog/why-reactjs-benefits-business-owners/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-reactjs-benefits-business-owners-index-mdx" */),
  "component---src-templates-case-studies-apply-js": () => import("./../../../src/templates/case-studies/apply.js" /* webpackChunkName: "component---src-templates-case-studies-apply-js" */),
  "component---src-templates-case-studies-epic-delivery-js": () => import("./../../../src/templates/case-studies/epic-delivery.js" /* webpackChunkName: "component---src-templates-case-studies-epic-delivery-js" */),
  "component---src-templates-case-studies-fishency-js": () => import("./../../../src/templates/case-studies/fishency.js" /* webpackChunkName: "component---src-templates-case-studies-fishency-js" */),
  "component---src-templates-case-studies-frimb-food-drink-app-js": () => import("./../../../src/templates/case-studies/frimb-food-drink-app.js" /* webpackChunkName: "component---src-templates-case-studies-frimb-food-drink-app-js" */),
  "component---src-templates-case-studies-getitdone-js": () => import("./../../../src/templates/case-studies/getitdone.js" /* webpackChunkName: "component---src-templates-case-studies-getitdone-js" */),
  "component---src-templates-case-studies-jewlot-js": () => import("./../../../src/templates/case-studies/jewlot.js" /* webpackChunkName: "component---src-templates-case-studies-jewlot-js" */),
  "component---src-templates-case-studies-m-2-r-js": () => import("./../../../src/templates/case-studies/m2r.js" /* webpackChunkName: "component---src-templates-case-studies-m-2-r-js" */),
  "component---src-templates-case-studies-nurse-alert-js": () => import("./../../../src/templates/case-studies/nurse-alert.js" /* webpackChunkName: "component---src-templates-case-studies-nurse-alert-js" */),
  "component---src-templates-case-studies-real-time-tracking-solution-js": () => import("./../../../src/templates/case-studies/real-time-tracking-solution.js" /* webpackChunkName: "component---src-templates-case-studies-real-time-tracking-solution-js" */),
  "component---src-templates-case-studies-repd-js": () => import("./../../../src/templates/case-studies/repd.js" /* webpackChunkName: "component---src-templates-case-studies-repd-js" */),
  "component---src-templates-case-studies-seatsplanet-travel-app-js": () => import("./../../../src/templates/case-studies/seatsplanet-travel-app.js" /* webpackChunkName: "component---src-templates-case-studies-seatsplanet-travel-app-js" */),
  "component---src-templates-development-technology-single-js": () => import("./../../../src/templates/development-technology-single.js" /* webpackChunkName: "component---src-templates-development-technology-single-js" */),
  "component---src-templates-guide-to-hiring-single-js": () => import("./../../../src/templates/guide-to-hiring-single.js" /* webpackChunkName: "component---src-templates-guide-to-hiring-single-js" */),
  "component---src-templates-hire-technology-single-js": () => import("./../../../src/templates/hire-technology-single.js" /* webpackChunkName: "component---src-templates-hire-technology-single-js" */),
  "component---src-templates-portfolio-categories-js": () => import("./../../../src/templates/portfolio-categories.js" /* webpackChunkName: "component---src-templates-portfolio-categories-js" */),
  "component---src-templates-portfolio-single-js": () => import("./../../../src/templates/portfolio-single.js" /* webpackChunkName: "component---src-templates-portfolio-single-js" */)
}

